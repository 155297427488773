<template>
  <div>
    <PageLoader :storage="appLoading" />
    <v-dialog v-model="transferDialogue" width="400">
      <v-card>
       <v-layout v-if='socketData' pa-4 wrap justify-center>
          <v-flex xs12  v-if="socketData.transferData">
            <span
            v-if="socketData.transferData.transferredBy"
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
              >
              A New Transfer case request from  {{socketData.transferData.transferredBy.fullName}}
            </span>
          </v-flex>
            
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              :color="appColor"
              @click="transferDialogue=false"
              class="itemValue"
            >
              Cancel
            </v-btn>

            
              <!-- @click="activateUser" -->
          </v-flex>
          <!-- <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="transferDialogue = false"
              class="itemValue"
            >
              close
            </v-btn>
          </v-flex> -->
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12 md5>
        <v-layout
          wrap
          v-for="(item, i) in caseDetails"
          @click="clickItem(item)"
          style="cursor: pointer"
          :key="i"
          pt-4
        >
          <!-- <v-flex  v-if="item.status=='Pending'==playSound(
              'http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3'
          )">
          
            </v-flex> -->
          <v-flex
            xs3
            sm2
            md3
            lg2
            xl2
            :class="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? 'text-center'
                : 'text-left'
            "
            align-self-center
          >
            <v-avatar v-if="item && item.reportImages && item.reportImages.length > 0" size="62px">
              <img alt="Avatar" :src="mediaURL + item.reportImages[0]" />
            </v-avatar>
            <v-avatar v-else size="62px">
              <img alt="Avatar" src="@/assets/images/noimage.jpg" />
            </v-avatar>
          </v-flex>
          <v-flex xs8 sm7 md9 lg9 xl9>
            <v-layout wrap>
              <v-flex v-if="item.scenarioId" xs6 sm3 md3 lg9>
                <v-btn
                  x-small
                  depressed
                  class="py-2"
                  :outlined="item.status == 'Pending' ? true : false"
                  :ripple="false"
                  :color="item.status == 'Pending' ? '#deb710' : '#F5F5F5'"
                  style="letter-spacing: 0px"
                  ><span
                    style="
                      font-family: sofiaProRegular;
                      color: #000;
                      font-size: 8px;
                    "
                    >{{item.scenarioId.name}}
                  </span></v-btn
                >
              </v-flex>
              <v-flex xs4 sm3 md3 lg3 text-right>
                <span
                  class="text-uppercase"
                  style="
                    font-family: sofiaProRegular;
                    color: #deb710;
                    font-size: 8px;
                  "
                >
                  {{ item.statuscopy }}</span
                >
              </v-flex>

              <!-- hii{{ socketType }}<br />
              reportid{{ item._id }}<br />
              {{socketData}}
              socketid{{ socketData._id }}<br />
              id{{ $route.query.id }} -->

              <!-- <v-flex
                v-if="item.isAssignedToMe && appUser.role == 'official'"
                xs4
                sm3
                md3
                text-left
              >
                <span
                  class="text-uppercase"
                  style="
                    font-family: sofiaProRegular;
                    color: #deb710;
                    font-size: 8px;
                  "
                >
                  Assigned to you</span
                >
              </v-flex> -->
              <v-flex v-if="item.isTransferred" text-right xs4 sm3 md3>
                <span
                  class="text-uppercase"
                  style="
                    font-family: sofiaProRegular;
                    color: #deb710;
                    font-size: 8px;
                  "
                >
                  Transffered</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs8 sm7 md8 lg8 xl8>
                <span class="itemHeading">
                  {{ item.animalId.name }}
                </span>
              </v-flex>
              <v-flex xs4 sm4 md4 lg4 xl4 text-right>
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #000;
                    font-size: 10px;
                  "
                >
                  {{ timeSince(item.update_date) }}
                  <!-- <timeago :datetime="item.create_date" :auto-update="60"></timeago> -->
                </span>
              </v-flex>
              <v-flex xs12>
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                >
                  Threat Level:
                  <span v-if="item.threatlevel">
                    {{ formatText(item.threatlevel) }}</span
                  >
                  <span v-else>Unknown</span>
                </span>
                <br />
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                >
                  Location Name:
                  <span v-if="item.locationname">{{
                    formatText(item.locationname)
                  }}</span>
                  <span v-else>Unknown</span>
                </span>
                <br />
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                >
                  Reported Date & Time:
                  <span v-if="item.reportTime">{{
                    formatReportTime(item.reportTime)
                  }}</span>
                  <span v-else>Unknown</span>
                </span>
                <br />
                <span
                  style="
                    font-family: sofiaProRegular;
                    color: #626262;
                    font-size: 12px;
                  "
                  v-if="item.addedBy.role"
                >
                  Sighted By:
                   <span v-if="item.addedBy.role === 'User'">
                    <span v-if="item.addedBy.userId">{{ item.addedBy.userId.name }}</span>
                  </span>
                  <span v-if="item.addedBy.role === 'MASTER'">
                    <span v-if="item.addedBy.admin">{{ item.addedBy.admin.masterName }}</span>
                  </span>
                  <span v-if="item.addedBy.role === 'EmergencyUser'">
                    <span v-if="item.addedBy.userId">{{ item.addedBy.userId.name }}</span>
                  </span>
                  <span v-if="item.addedBy.role === 'RANGE'">
                    <span v-if="item.addedBy.range.accessCredentials">{{ item.addedBy.range.accessCredentials.username }}</span>
                  </span>
                  <span v-if="item.addedBy.role === 'DIVISION'">
                    <span v-if="item.addedBy.division.accessCredentials">{{ item.addedBy.division.accessCredentials.username }}</span>
                  </span>  
                  <span v-if="item.addedBy.role === 'RRT'">
                    <span v-if="item.addedBy.userId.name">{{ item.addedBy.userId.name }}</span>
                  </span>
                  <span v-if="item.addedBy.role === 'EmergencyUser' && !item.addedBy.userId">
                    <span>Emergency User</span>
                  </span>
                </span>
              </v-flex>
              <v-flex xs12 sm9 md12 lg12 xl2 pt-1 v-if="appUser === 'admin'">
                <v-btn
                  width="100%"
                  shaped
                  small
                  color="#000000"
                  @click="
                    $router.push({
                      path: '/Admin/Cases/caseDetails',
                      query: { id: item._id, status: item.status },
                    }) && checkSound(item)
                  "
                >
                  <span
                    v-if="item.status == 'Pending'"
                    style="color: #fff; font-size: 10px"
                    >Take Action
                  </span>
                  <span v-else style="color: #fff; font-size: 10px"
                    >View Details
                  </span>
                  <v-icon
                    style="
                      font-family: sofiaProRegular;
                      color: #ffffff;
                      font-size: 14px;
                    "
                    class="pl-3"
                    >mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 sm9 md12 lg12 xl2 pt-1 v-if="appUser === 'range' || appUser === 'division' || appUser === 'EmergencyUser' || appUser === 'RRT'">
                <v-btn
                  width="100%"
                  shaped
                  small
                  color="#000000"
                  @click="
                    $router.push({
                      path: '/officers/cases/caseDetails',
                      query: { id: item._id, status: item.status },
                    }) && checkSound(item)
                  "
                >
                  <span
                    v-if="item.status == 'Pending'"
                    style="color: #fff; font-size: 10px"
                    >Take Action
                  </span>
                  <span v-else style="color: #fff; font-size: 10px"
                    >View Details
                  </span>
                  <v-icon
                    style="
                      font-family: sofiaProRegular;
                      color: #ffffff;
                      font-size: 14px;
                    "
                    class="pl-3"
                    >mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex>
              <!-- <v-flex xs12 sm9 md12 lg12 xl2 pt-1>
                <v-btn
                  width="100%"
                  shaped
                  small
                  color="#000000"
                  @click="confirmDelete(item._id)"
                >
                  <span style="color: #fff; font-size: 10px">Delete Case</span>

                  <v-icon
                    style="
                      font-family: sofiaProRegular;
                      color: #ffffff;
                      font-size: 14px;
                    "
                    class="pl-3"
                    >mdi-delete
                  </v-icon>
                </v-btn>
              </v-flex> -->
            </v-layout>
          </v-flex>
          <v-flex xs12 :key="i" pt-4>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 v-if="caseMsg" text-center>
        <span style="fonr-family: sofiaProBold; font-size: 18px"
          >No Data Found</span
        >
      </v-flex> -->
      <v-flex xs12 md7>
        <div class="posSticky" id="map"></div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Delete Case</v-card-title>
        <v-card-text> Are you sure you want to delete this case? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteCase">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
 <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
var audio;
var marker;
var infowindow;
var content;
// import axios from "axios";
// import Socket from "@/Sockets/socket";
// import io from "socket.io-client";
export default {
  props: ["authentication", "storage", "pageType"],
  data() {
    return {
      appLoading: false,
      map: "",
      caseDetails: [],
      isConnected: false,
      isConnected1: "hu",
      name: "Bob",
      message: "click on the buttons",
      socketType: null,
      socketData: {},
      infoItem: null,
      caseMsg: null,
      content: null,
      transferDialogue: false,
      infowindow: null,
      dialog: false,
      markers: [], // Array to hold markers
      infowindows: [], // Array to hold info windows

    };
  },

  computed: {
    myData() {
      if (this.storage.length > 0) return this.storage;
      else return [];
    },
    dataLength() {
      return this.len;
    },
    appUser() {
      return this.$store.state.userRole;
    },
    // authValue()
    // {
    //   return this.$store.state.authentication
    // }
  },

  mounted() {
    this.loadData();
    // if (!this.$route.query.id || this.$route.query.id == "activeCases")
    // this.getSocketData();
    // this.socketAuth();
  },
  watch: {
    socketType() {
      // if(this.socketType == "newConflict")
      //   {
      //    if (this.socketData.status == "Pending") {
      //     this.playSound(require("../../../assets/audios/alarm.mp3"));
      //   }
      //   }
      if (
        this.socketType == "newConflict" &&
        (!this.$route.query.id || this.$route.query.id == "activeCases")
      ) {
        if (this.socketData.status == "Pending") {
          // this.playSound(require("../../../assets/audios/alarm.mp3"));
        }
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () {
          marker.setAnimation(null);
        }, 300000);
        // this.transferDialogue=true
        this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
        // this.socketData = {};
      }
      if (this.socketType == "reportClose") {
        if (
          !this.$route.query.id ||
          this.$route.query.id == "activeCases" ||
          this.$route.query.id == "assignedCases"
        ) {
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id)
              this.caseDetails.splice(i, 1);
          }
        } else if (this.$route.query.id == "resolvedCases") {
          console.log("returm", this.changeCaseDetails(this.socketData));
          this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          this.socketData = {};
        }
      }

      if (this.socketType == "reportAssign") {
        if (!this.$route.query.id || this.$route.query.id == "activeCases") {
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id)
              this.caseDetails.splice(i, 1);
          }
        } else if (this.$route.query.id == "assignedCases") {
          this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          this.socketData = {};
        }
      }
      if (this.socketType == "reportCapture") {
        if (this.$route.query.id == "assignedCases") {
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id)
              this.caseDetails.splice(i, 1);
          }
        } else if (this.$route.query.id == "capturedCases") {
          this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          this.socketData = {};
        }
      }
      if (this.socketType == "reportRelease") {
        if (this.$route.query.id == "capturedCases") {
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id)
              this.caseDetails.splice(i, 1);
          }
        } else if (this.$route.query.id == "resolvedCases") {
          this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          this.socketData = {};
        }
      }
      if (this.socketType == "reportResolve") {
        if (this.$route.query.id == "assignedCases") {
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id)
              this.caseDetails.splice(i, 1);
          }
        } else if (this.$route.query.id == "resolvedCases") {
          this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          this.socketData = {};
        }
      }
      if (this.socketType == "reportLocationUpdate") {
        if (this.$route.query.id == "assignedCases") {
          var flag = 0;
          for (var i = 0; i < this.caseDetails.length; i++) {
            if (this.caseDetails[i]._id == this.socketData._id) flag = 1;
          }
          if (flag == 0) {
            this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
            this.socketData = {};
          }
        }
      }
      if (this.socketType == "reportTransferRequest") {
        if ( this.$route.query.id == "assignedCases") {

          // this.caseDetails.unshift(this.changeCaseDetails(this.socketData));
          // this.socketData = {};
          this.transferDialogue = true;

          
        }
      }
    },
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  methods: {
    
    formatText(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    confirmDelete(caseId) {
      this.caseToDelete = caseId;
      this.dialog = true;
    },
    formatReportTime(dateString) {
      const date = new Date(dateString);
      const time = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      });
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      });
      return `${time} ${formattedDate}`;
    },
    clickItem(item) {
        const markerIndex = this.caseDetails.indexOf(item);
        if (markerIndex !== -1) {
          google.maps.event.trigger(this.markers[markerIndex], "click");
        }
      },
    changeCaseDetails(caseData) {
      if (caseData.reportImages.length > 0)
        caseData.image = caseData.reportImages[0];
      else caseData.image = "noimage.jpg";
      //if (!caseData.userId.fullName) caseData.userId.fullName = "Unknown";
      if (!caseData.address) caseData.address = "Unknown Address";

      return caseData;
    },

    checkSound(item) {
      if (item._id == this.socketData._id) {
        audio.loop = false;

        audio.pause();
      }
    },
    loadData() {
      this.caseDetails = this.myData;
      for (var i = 0; i < this.caseDetails.length; i++) {
        this.caseDetails[i].image = null;
        if (this.caseDetails[i].reportImages.length > 0)
          this.caseDetails[i].image = this.caseDetails[i].reportImages[0];
        else this.caseDetails[i].image = "noimage.jpg";
        if (!this.caseDetails[i].address)
          this.caseDetails[i].address = "Unknown Address";
        this.initMap();
      }
    },
    // clickItem(item) {
    //   // this.infoItem = item;
    //   for (var i = 0; i < this.storage.length; i++) {
    //     if (this.storage[0]._id == item._id) {
    //       infowindow.open(map, marker);
    //     }
    //   }
    // },
    playSound(sound) {
      if (sound) {
        audio = new Audio(sound);
        if (this.socketData.status == "Pending") {
          audio.play();
          audio.loop = true;
        } else {
          audio.pause();
          audio.loop = false;
        }
      }
    },
    getSocketData() {
      Socket.dataFunction(this); // <---read data
    },
    // socketAuth() {
    //   Socket.authFunction(this); // <---read data
    // },
    infoWIndow() {},
  //   initMap() {
  //   const mapCanvas = document.getElementById("map");
  //   if (!mapCanvas) return;

  //   const defaultLocation = { lat: 0, lng: 0 }; // Default location if caseDetails is empty

  //   const mapOptions = {
  //     center: new google.maps.LatLng(
  //       this.caseDetails[0]?.location[1] || defaultLocation.lat,
  //       this.caseDetails[0]?.location[0] || defaultLocation.lng
  //     ),
  //     zoom: 7,
  //   };
  //   this.map = new google.maps.Map(mapCanvas, mapOptions);

  //   // Clear existing markers and infowindows
  //   this.markers.forEach((marker) => marker.setMap(null));
  //   this.infowindows.forEach((infowindow) => infowindow.close());

  //   this.markers = [];
  //   this.infowindows = [];

  //   // Add markers and info windows
  //   this.caseDetails.forEach((item) => {
  //     const position = new google.maps.LatLng(item.location[1], item.location[0]);
  //     const marker = new google.maps.Marker({
  //       position,
  //       map: this.map,
  //       title: `Case ID: ${item._id}`,
  //     });

  //     const infowindow = new google.maps.InfoWindow({
  //       content: `
  //         <div style="width: 400px; height: 210px;">
  //               <div class="xs12 flex">
  //                 <h2 style="font-family: sofiaProMedium;">${item.animalId.name}</h2>
  //                 </div><br>
  //               <div class="xs12 flex">
  //                 <p><span style="font-size: 13px;font-family: sofiaProMedium;">Location Name:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.locationname}</span></p>
  //                 <p><span style="font-size: 13px;font-family: sofiaProMedium;">Coordinates:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.location[1]}, ${item.location[0]}</span></p>
  //                 <p><span style="font-size: 13px;font-family: sofiaProMedium;">Threat Level:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.threatlevel}</span></p>
  //                 <p><span style="font-size: 13px;font-family: sofiaProMedium;">Status:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.status}</span></p>
  //               </div>
  //                         <div class="xs12 flex">
  //               <div class="layout wrap">
  //                 <div class="flex xs5 text-left">
  //                   <span class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--medium"
  //                     style="background-color: #FF6907;">
  //                     <span class="v-btn__content py-2 px-3">
  //                       <i style="color:#FFF;font-size:18px;" class="fas fa-location-arrow"></i>
  //                       <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #FFF;"> Direction</span>
  //                     </span>
  //                   </span>
  //                 </div> 
  //                 <div class="flex xs4 text-left">
  //                   <span class="itemKey text-capitalize v-btn v-btn--router v-btn-outlined theme--light v-size--medium"
  //                     style="background-color: #FFF; border:.5px solid #EAEAEA; cursor:pointer">
  //                     <span class="v-btn__content py-2 px-3">
  //                       <i style="color:#000;font-size:18px;" class="fas fa-phone-alt"></i>
  //                       <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #000;"> Call</span>
  //                     </span>
  //                   </span>
  //                 </div>
  //                 <div class="flex xs3 text-left">
  //                   <span class="itemKey text-capitalize v-btn v-btn--router v-btn-outlined theme--light v-size--medium"
  //                     style="background-color: #FFF; border:.5px solid #EAEAEA; cursor:pointer">
  //                     <span class="v-btn__content py-2 px-3">
  //                       <i style="color:#000;font-size:18px;" class="fas fa-share-alt"></i>
  //                       <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #000;"> Share</span>
  //                     </span>
  //                   </span>
  //                 </div>
  //               </div>
  //             </div> 
  //             </div>
  //       `,
  //     });

  //     marker.addListener("click", () => {
  //       this.infowindows.forEach((info) => info.close());
  //       infowindow.open(this.map, marker);
  //     });

  //     this.markers.push(marker);
  //     this.infowindows.push(infowindow);
  //   });
  // },
  initMap() {
  const mapCanvas = document.getElementById("map");
  if (!mapCanvas) return;

  const defaultLocation = { lat: 0, lng: 0 }; // Default location if caseDetails is empty

  const mapOptions = {
    center: new google.maps.LatLng(
      this.caseDetails[0]?.location[1] || defaultLocation.lat,
      this.caseDetails[0]?.location[0] || defaultLocation.lng
    ),
    zoom: 7,
  };
  this.map = new google.maps.Map(mapCanvas, mapOptions);

  // Clear existing markers and infowindows
  this.markers.forEach((marker) => marker.setMap(null));
  this.infowindows.forEach((infowindow) => infowindow.close());

  this.markers = [];
  this.infowindows = [];

  // Convert icon paths to URLs using require
  const icons = {
    High: require('@/assets/images/highthreat.png'),
    Medium: require('@/assets/images/mediumthreat.png'),
    Low: require('@/assets/images/lowthreat.png'),
  };

  // Add markers and info windows
  this.caseDetails.forEach((item) => {
    const position = new google.maps.LatLng(item.location[1], item.location[0]);

    // Determine the icon based on threat level
    const icon = icons[item.threatlevel] || icons['Low']; // Default to Low if threat level is not defined

    const marker = new google.maps.Marker({
      position,
      map: this.map,
      title: `Threat Level: ${item.threatlevel}`,
      icon: icon, // Set the icon based on threat level
    });

    const infowindow = new google.maps.InfoWindow({
      content: `
        <div style="width: 400px; height: 210px;">
          <div class="xs12 flex">
            <h2 style="font-family: sofiaProMedium;">${item.animalId.name}</h2>
          </div><br>
          <div class="xs12 flex">
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Location Name:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.locationname}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Coordinates:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.location[1]}, ${item.location[0]}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Threat Level:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.threatlevel}</span></p>
            <p><span style="font-size: 13px;font-family: sofiaProMedium;">Status:</span><span style="font-size: 12px;font-family: sofiaProMedium;"> ${item.status}</span></p>
          </div>
          <div class="xs12 flex">
            <div class="layout wrap">
             <div class="flex xs5 text-left">
                <a 
                  href="https://www.google.com/maps/dir/?api=1&destination=${item.location[1]},${item.location[0]}" 
                  target="_blank" 
                  class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--medium"
                  style="background-color: #FF6907; text-decoration: none;">
                  <span class="v-btn__content py-2 px-3">
                    <i style="color:#FFF;font-size:18px;" class="fas fa-location-arrow"></i>
                    <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #FFF;"> Direction</span>
                  </span>
                </a>
              </div> 
              <div class="flex xs4 text-left">
                <span class="itemKey text-capitalize v-btn v-btn--router v-btn-outlined theme--light v-size--medium"
                  style="background-color: #FFF; border:.5px solid #EAEAEA; cursor:pointer">
                  <span class="v-btn__content py-2 px-3">
                    <i style="color:#000;font-size:18px;" class="fas fa-phone-alt"></i>
                    <span class="pl-1" style="font-family: sofiaProMedium;font-size:16px;color: #000;"> Call</span>
                  </span>
                </span>
              </div>
            </div>
          </div> 
        </div>
      `,
    });

    marker.addListener("click", () => {
      this.infowindows.forEach((info) => info.close());
      infowindow.open(this.map, marker);
    });

    this.markers.push(marker);
    this.infowindows.push(infowindow);
  });
},

  timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
    deleteCase() {
      this.dialog = false;
      axios({
        method: "POST",
        url: "/report/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.caseToDelete,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
            // this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$emit("showSnackbar", "Error deleting case");
        });
    },
  },
};
</script>
<style  >
.posSticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}
</style>
